@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .mex-span {
    background: #1F8E03;
    background: linear-gradient(to bottom right, #1F8E03 0%, #FFFFFF 50%, #F41915 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
:root {
  /* --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
    */
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  /* Sticky Header */
    .js-page-header--is-sticky {
      @apply: bg-white/50;
    }

    .js-page-header--is-sticky.page-header--transparent,
    .dark .js-page-header--is-sticky {
      @apply: bg-jacarta-700/50;
    }

    .text-balance {
      text-wrap: balance;
    }

    .debug-red {
      border: solid 1px red;
    }

    .debug-blue {
      border: solid 1px blue;
    }

    .debug-green {
      border: solid 1px green;
    }

    .debug-yellow {
      border: solid 1px yellow;
    }
  }

/*
Formateo de Markdown
**/

.md-content {
  justify-content: center;
  text-align: justify;

  border: 0 1px 0 1px #7444ff dotted;
  line-height: 1.9;

  pre {
    padding: 16px;
    border-radius: 4px;
    overflow: auto;
    font-size: 14px;
    margin-bottom: 1.5rem;
    margin-top: 0.8rem;
    padding: 1.7rem 0 1.6rem 0.4rem;
    border-radius: 12px;
    overflow: hidden;
    background-image: url(/images/window-buttons.png);
    background-color: rgb(40, 42, 54);
    background-repeat: no-repeat;
    background-size: 44px 10px;
    background-position: 16px 14px;
  }
  p > code,
  li > code {
    color: #e02424;
    padding: 1px 5px;
    background: #eee;
    text-shadow: none;
    border-radius: 2px;
  }

  p > a {
    background: #1F8E03;
    background: linear-gradient(to bottom right, #1F8E03 0%, #FFFFFF 50%, #F41915 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ul {
    list-style: none;
    margin: revert;
    padding: revert;

    li {
      margin: revert;
      padding: revert;
      max-width: 600px;
      position: relative;
    }

    a {
      max-width: 500px;
      position: relative;
      background:  transparent;
      color: #9e7cff;
      display: block;
      cursor: pointer;
      padding: 0px 25px;
      margin: 0px 5px;

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #8358ff;
        position: absolute;
        border-radius: 50%;
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:hover {
        box-shadow: 0 8px 15px rgb(66 139 202 / 0.55);
        background: #b9a0ff;
        color: #131740;
        border-radius: 5px;
      }
      &:hover:before {
        box-shadow: 0 8px 15px rgb(66 139 202 / 0.55);
        background: #b9a0ff;
        color: #131740;
      }
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.3333333;
    text-align: left;
    text-shadow: 3px 3px 3px #a1a2b3;
    text-transform: capitalize;
    color: #7444ff;
  }

  h3 {
    font-size: 0.98rem;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.3333333;
    text-align: left;
    text-shadow: 1px 3px 3px #a1a2b3;
    text-transform: capitalize;
    color: #7444ff;
  }
}

.md-guest {
  @apply text-left border-green-400 border-dotted h-full w-full md:mx-auto md:pt-6 md:px-2;

  line-height: 1.9;

  pre {
    padding: 16px;
    border-radius: 4px;
    overflow: auto;
    font-size: 14px;
    margin-bottom: 1.5rem;
    margin-top: 0.8rem;
    padding: 1.7rem 0 1.6rem 0.4rem;
    border-radius: 12px;
    overflow: hidden;
    background-image: url(/images/window-buttons.png);
    background-color: rgb(40, 42, 54);
    background-repeat: no-repeat;
    background-size: 44px 10px;
    background-position: 16px 14px;
  }
  p {
    @apply text-jacarta-300;
  }
  p > code,
  li > code {
    color: #e02424;
    padding: 1px 5px;
    background: #eee;
    text-shadow: none;
    border-radius: 2px;
  }

  p > a {
    background: #1F8E03;
    background: linear-gradient(to bottom right, #1F8E03 0%, #FFFFFF 50%, #F41915 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 5px 15px;

    &:hover {
      cursor: pointer;
      background: #bbf7d0;
      background-color: #bbf7d0;
      border-radius: 5px;
      color: #131740;
      -webkit-text-fill-color: #131740;
    }
  }

  ul {
    list-style: none;
    margin: revert;
    padding: revert;

    li {
      margin: revert;
      padding: revert;
      max-width: 700px;
      position: relative;
      left: -35px;
      color: #a1a2b3;

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #4ade80;
        position: absolute;
        border-radius: 50%;
        left: -15px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    a {
      max-width: 300px;
      position: relative;
      color: #bbf7d0;

      cursor: pointer;
      padding: 5px 15px;
      margin: 0px 5px;

      &:hover {
        background-color: #bbf7d0;
        color: #131740;
        border-radius: 5px;
      }
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.3333333;
    text-align: left;

    text-transform: capitalize;
    color: #ef4444;
  }

  h3 {
    font-size: 0.98rem;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.3333333;
    text-align: left;
    text-transform: capitalize;
    color: #4ade80;
  }
}